<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img src="/assets/images/memlist-logo-vit-small.fw.png" alt="Logo" />
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" ref="kt_aside_toggle">
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg class="svg-icon" src="/assets/svg/Angle-double-left.svg" />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from 'vuex';
import objectPath from 'object-path';
import KTLayoutBrand from '@/assets/js/layout/base/brand.js';
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';

export default {
  name: 'KTBrand',
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs['kt_brand']);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs['kt_aside_toggle']);
  },
  methods: {},
  computed: {
    ...mapGetters(['layoutConfig']),

    allowMinimize() {
      return !!this.layoutConfig('aside.self.minimize.toggle');
    }
  }
};
</script>

<template>
  <ul class="menu-nav">
    <router-link to="/ml-dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-start-up"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-users" v-slot="{ href, navigate, isActive, isExactActive }" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-safe"></i>
          <span class="menu-text">Användare</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-billing" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-9"></i>
          <span class="menu-text">Kostnader</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-companies" v-slot="{ href, navigate, isActive, isExactActive }" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-open-box"></i>
          <span class="menu-text">Föreningslista</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-system" v-slot="{ href, navigate, isActive, isExactActive }" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-cogwheel"></i>
          <span class="menu-text">System</span>
        </a>
      </li>
    </router-link>

    <router-link to="/ml-apikeys" v-slot="{ href, navigate, isActive, isExactActive }" >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon">
            <inline-svg src="/assets/svg/key.svg" style='width: 17px; height: 17px;'></inline-svg>
          </span>
          <span class="menu-text">API Nycklar</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>
<style lang="scss" scoped>
li:hover > a > span > svg,
.menu-item-active > a > span > svg {
  fill: #3699FF;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KTMenu',
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  data() {
    return {
      access: {}
    }
  },
  mounted() {

  },
  watch: {
    
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <div class='d-flex justify-content-between m-2 m-sm-0'>
      
    </div>
  </div>
</template>
<style lang="scss" scoped>
.topnav-company-list-wrapper {
  min-width: 250px;
  span {
    font-size: 0.8rem;
  }
}
.topnav-company-list {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}
ul {
  transform: translate3d(-121px, 36px, 0px) !important;
  background-color: red;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';
export default {
  name: 'KTMenu',
  data() {
    return {
      periods: [],
      period: {},
      company: {},
      filterText: '',
      filtered: [],
      initialized: false
    };
  },
  props: {
    
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  mounted() {
    // load companies
  },
  watch: {
    
  },
  methods: {
    
  }
};
</script>
